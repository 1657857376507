import React from 'react'
// import logo from '../images/logo.png'
import logo from '../images/browzedLogo.png'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAlert } from "react-alert";
import logoutImg from '../images/logout.png'
const Header = () => {
    const alert = useAlert();
    const username = sessionStorage.getItem('username')
    const companyName = sessionStorage.getItem('companyName')
    const navigate = useNavigate();
    const handleLogout = () =>
    {
        alert.removeAll()
        sessionStorage.clear();
        navigate('/')
    }
  return (
    <div>
      <header className="headerMain">
	    <div className="wrapper">
            <div className="headerTop">
                <div id="" className="logo" style={{display:"block"}}>
                    <a>
                        <img src={logo} style={{ maxWidth: "125px" }}/>
                    </a>
                </div>
                <nav>
                    <ul>
                        <li>
                            <NavLink to="/dashboard"
                              className="advertiseFlex"
                              activeClassName="active"
                             >Dashboard</NavLink>
                        </li>
                        <li>
                            <NavLink to="/listing" className="advertiseFlex" activeClassName="active" >IP Log</NavLink>
                        </li>
                    </ul>
                </nav>
                {/* <div className="loggedInUser">
                    <a>
                        <label>{username!== "" ? username :"John Doe" }</label><br />
                        <strong>{companyName !== "" ? companyName :"XYZ Company" }</strong>
                    </a>
                    <div className="shortLink">
                        <ul>
                            <li onClick={handleLogout}>
                                Logout
                            </li>
                        </ul>
                    </div>
                </div> */}
                <div class="loggedInUser">
                <a>
                <label>{username!== "" ? username :"John Doe" }</label><br />
                <strong>{companyName !== "" ? companyName :"XYZ Company" }</strong>
                </a>
                <a class="shortLink" onClick={handleLogout}>
                    <img src={logoutImg} alt="logout" />
                </a>
            </div>
                
            </div>
	    </div>
    </header>
    </div>
  )
}

export default Header
