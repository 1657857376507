import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import {transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import LoginPage from './screens/LoginPage';
import DashboardPage from './screens/DashboardPage';
import ListingPage from './screens/ListingPage';
const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
};
export default function App() {
  return (
  <>
    <AlertProvider template={AlertTemplate} {...options}>
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/listing" element={<ListingPage />} />
      </Routes>
    </Router>
    </AlertProvider>
  </>
  );
}