/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect, useState, useRef} from 'react'
import { useNavigate } from "react-router-dom";
import DataTable, { defaultThemes } from 'react-data-table-component';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Header from '../component/Header'
import dawnloadImg from '../images/download.png'
import mapPinImg from '../images/map-pin.png'
import {Triangle} from 'react-loader-spinner'
import { addDays } from 'date-fns';
import { useAlert } from "react-alert";
const ListingPage = () => 
{
  const alert = useAlert();
  let trackerCode, token, startDate, endDate, csvData;
  let isFullDownload = 0;
  const startDateRef = useRef(new Date().toLocaleDateString('en-US'));
  const endDateRef = useRef(new Date().toLocaleDateString('en-US'));
  const dateRangePickerRef = useRef(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [onlyPaidTraffic]= useState(1);
  const [isEmailOnly, setIsEmailOnly]= useState(1);
  const [isPhoneOnly, setIsPhoneOnly]= useState(1); 
  const [showPicker, setShowPicker] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: 'selection',
  });
  const[date,setDate] = useState("")
  const searchInputRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(`Both Email/Phone`);
  const [clearFilter, setClearFilter] = useState(false);
  const [mounted, setMounted] = useState(false);
  const options = [
    { value: `both`, label: `Both Email/Phone` },
    { value: `email`, label: `Email` },
    { value: `phone`, label: `Phone` },
    { value: `all`, label: `All` },
  ];
  // Use this ref to avoid calling getIpLogs multiple times in the same render cycle
  const isGetIpLogsCalled = useRef(false);
  //style for datatable
  const customStyles = 
  {
    header: {
    		style: {
    			minHeight: `56px`,
    		},
      	},
      	headRow: {
      		style: {
      			borderTopStyle: `solid`,
      			borderTopWidth: `1px`,
      			borderTopColor: defaultThemes.default.divider.default,
      		},
      	},
      	headCells: {
          className: `custom-data-table`,
      		style: {
      				borderRightStyle: 'solid',
              borderLeftStyle: 'solid',
      				borderRightWidth: '1px',
              borderLeftWidth: '1px',
      				borderRightColor: defaultThemes.default.divider.default,
              borderLeftColor: defaultThemes.default.divider.default,
              width:50,
      		},
      	},
      	cells: {
      		style: {
              borderLeftStyle: 'solid',
      				borderRightWidth: '1px',
              borderLeftWidth: '1px',
              width:50,
      				borderRightColor: defaultThemes.default.divider.default,
              borderLeftColor: defaultThemes.default.divider.default,
              whiteSpace: 'nowrap',
      		},
      	},
        expandableRows: {
          style: {
            '& .rdt_Expander': {
              justifyContent: `flex-end`, // Aligns the expand icon to the right
              marginRight: `10px`, // Adds some right margin to the expand icon
              borderLeftWidth: `1px`,
              borderLeftStyle: `solid`,
            },
            
          },
        },
  };
  //datatable column 
  //this column is dsc order i have set style ltr in style.css
  const columns = //column for datatable
  [
   
    // {
    //   name: "OS Platform",
    //   selector: row => row.OS_Platform === "" ? "-" : row.OS_Platform,
    //   center: false,
    //   sortable: true
    // },
    // {
    //   name: "OS Version",
    //   selector: row => row.OS_Version === "" ? "-" : row.OS_Version,
    //   center: false,
    //   sortable: true
    // },
    // {
    //   name: "OS Name",
    //   selector: row => row.OS_Name === "" ? "-" : row.OS_Name,
    //   center: false,
    //   sortable: true
    // },
    // {
    //   name: "Browser Version",
    //   selector: row => row.Browser_Version === "" ? "-" : row.Browser_Version,
    //   center: false,
    //   sortable: true
    // },
    // {
    //   name: "Browser Name",
    //   selector: row => row.browserName === "" ? "-" : row.browserName,
    //   center: false,
    //   sortable: true
    // },
    // {
    //   name: "Type",
    //   selector: row => row.Type === "" ? "-" : row.Type,
    //   center: false,
    //   sortable: true
    // },
    // {
    //   name: "Model",
    //   selector: row => row.model === "" ? "-" : row.model,
    //   center: false,
    //   sortable: true
    // },
    // {
    //   name: "Brand",
    //   selector: row => row.brand === "" ? "-" : row.brand,
    //   center: false,
    //   sortable: true
    // },
    // {
    //   name: "Device",
    //   selector: row => row.Device === "" ? "-" : row.Device,
    //   center: false,
    //   sortable: true
    // },
    {
      name: 'Phone',
      selector: row => row.Phone === "" ? "-" : row.Phone,
      center: false,
      sortable: true,
    },
    {
      name: 'URLRef',
      center: false,
      sortable: true,
      selector: row => <span title={row.URLRef}>{row.URLRef}</span>,
      grow: 2,
    },
    {
      name: 'Email',
      selector: row => row.Email  === "" ? "-" : row.Email,
      sortable: true,
      center: false,
      grow: 1.5,
    },
    {
      name: 'Name',
      selector: row => row.First  === "" ? "-" : `${row.First} ${row.Last}`,
      sortable: true,
      center: false,
    },
    {
      name: 'TimeStamp',
      selector: row => row.TimeStamp,
      sortable: true,
      center: false,
    },
    {
      name: 'Source IP',
      selector: row => row.SourceIP,
      sortable: true,
      center: false,
      // width:50
    },
  ];
  // getIpLogs fun start here
  const getIpLogs = async (page,perPage) => 
  {
    if (!isGetIpLogsCalled.current) 
    {
        isGetIpLogsCalled.current = true;
        trackerCode = sessionStorage.getItem(`trackerCode`);
        token = sessionStorage.getItem(`token`);
        if (!trackerCode || !token) {
          navigate('/');
        }
        try 
        {
            const headers = 
            {
              Authorization: `Bearer ${token}`,
              'Content-Type': `application/json`,
            };
            let dataConfig;
            // console.log("isFullDownload >> " + isFullDownload)
            
            if(isFullDownload === 0)
            {
              setLoading(true);
              dataConfig = {
                trackercode: trackerCode,
                StartDate: startDate, // Use the startDate state here
                EndDate: endDate, // Use the endDate state here
                OnlyPaidTraffic: onlyPaidTraffic,
                IsEmailOnly: isEmailOnly,
                IsPhoneOnly: isPhoneOnly,
                PageNo: page,
                RowsPerPage: perPage,
              };
            }
            else
            {
              setIsDownload(true);
              dataConfig = {
                trackercode: trackerCode,
                StartDate: startDate, // Use the startDate state here
                EndDate: endDate, // Use the endDate state here
                OnlyPaidTraffic: 1,
                IsEmailOnly: isEmailOnly,
                IsPhoneOnly: isPhoneOnly,
                isFullDownload: isFullDownload
              };
            }
            let queryString = JSON.stringify(dataConfig);
            // console.log(queryString)
            const response = await fetch(`https://ipapibeta.datazapp.com/PixelLogReport?${queryString}`, {
              method: 'GET',
              headers,
            });
            let status = response.status;
            let message = response.statusText
            if (status === 200) 
            {
              const responseData = await response.json();
              console.log("isFullDownload >> " + isFullDownload);
              if(isFullDownload === 0)
              {
                console.log(responseData.Data.length)
                let ipLogArrayLength = responseData.Data.length;
                if (ipLogArrayLength !== 0)
                {
                  setData(responseData.Data);   
                  setTotalRows(responseData.Data[0].OverallCount);
                  // console.log("totalRows >> " + totalRows);
                  // console.log("perPage >>" + perPage)
                }
                else
                {
                  alert.show("You have No Data")
                }
              }
              else
              {
                  csvData = responseData.Data;
                // console.log(csvData)
              }
            }
            else if(status === 204 )
            {
                // alert("You have no data");
                alert.show(message)
            }
            else if (status === 500)
            {
              alert.show(message)
            }
            else if (status === 401)
            {
              alert.error(message)
              sessionStorage.clear();
              navigate("/")
            }
        } 
        catch (error)
        {
          console.error(error);
          // alert.error(error)
        }
        finally 
        {
          setIsDownload(false)
          setLoading(false);
        }
      }
  }; // getIpLogs fun ends here
  // getIpLogsViaSearch strat here
  const getIpLogsViaSearch = () =>
  {
    alert.removeAll();
    setData("");
    isGetIpLogsCalled.current = false;
    setPageNo(1);
    setTotalRows(0)
    let tempDate = date.split(` To `);
    startDate = tempDate[0];
    endDate = tempDate[1];
    getIpLogs(pageNo, perPage)
    setClearFilter(true);
  }//getIpLogsViaSearch fun ends here
  // getLastWeekDates start here
  const getLastWeekDates = () => 
  {
    const today = new Date();
    const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const start = `${lastWeek.getMonth() + 1}/${lastWeek.getDate()}/${lastWeek.getFullYear()}`;
    const end = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
    return { start, end };
  };//getLastWeekDates ends here
  //pagechange  func start
  const pageChange = (pageNo) => 
  {
    alert.removeAll();
    if (mounted) 
    {
      isGetIpLogsCalled.current = false;
      setPageNo(pageNo);
      let tempDate = date.split(` To `);
      startDate = tempDate[0];
      endDate = tempDate[1];
      getIpLogs(pageNo, perPage);
    }
  };//pageChange ends here
  useEffect(() => 
  {
    alert.removeAll()
    trackerCode = sessionStorage.getItem(`trackerCode`);
    token = sessionStorage.getItem(`token`);
    if (!trackerCode || !token) 
    {
      navigate(`/`);
    } else
    {
       const { start, end } = getLastWeekDates();
        startDate = start;
        endDate = end;
        setDate(`${startDate} To ${endDate}`)
      if(startDate === start && end === endDate)
      {
        // console.log("iplog call useEffect")
        // console.log(startDate)
        // console.log(endDate)
        getIpLogs(pageNo, perPage);
        setMounted(true);
      }
    }
  }, []);
  // Add an event listener to the document when the component mounts
  useEffect(() => 
  {
    const handleDocumentClick = (e) => 
    {
      // Check if the clicked element is the input element
      if (searchInputRef.current === e.target) 
      {
        // console.log ("325 >> if")
        return; // Ignore the click if it's the input element
      }
      else if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(e.target)) {
        // If the click was outside the date range picker, close the date range picker
        setShowPicker(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);
    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);
  const handleSelectChange = (event) => 
  {
    // console.log("handleSelectChange >> " + event.target.value)
    // setFilterError(false)
    setSelectedOption(event.target.value);
    if (event.target.value === `email`) 
    {
      setIsEmailOnly(1);
      setIsPhoneOnly(0);
    }
    else if (event.target.value === `phone`)
    {
      setIsEmailOnly(0);
      setIsPhoneOnly(1);
    }
    else if (event.target.value === `both`) 
    {
      setIsEmailOnly(1);
      setIsPhoneOnly(1);
    } 
    else 
    {
      setIsEmailOnly(0);
      setIsPhoneOnly(0);
    }
  };
  const selectDateRange = (ranges) => {
    startDate = "";
    endDate = "";
    setDate("")
    let tempStartDate = ranges.selection.startDate;
    let tempEndDate = ranges.selection.endDate;
    let startDatePicker = new Date(tempStartDate).toLocaleDateString('en-US');
    let endDatepicker = new Date(tempEndDate).toLocaleDateString('en-US');
    startDateRef.current = startDatePicker;
    endDateRef.current = endDatepicker;
    setDate(`${startDatePicker} To ${endDatepicker}`);
    setDateRange(ranges.selection);
    if (ranges.selection.startDate !== ranges.selection.endDate) 
    {
      setShowPicker(false);
    }
  };
  const togglePicker = () => 
  {
    setShowPicker((prevState) => !prevState);
  };
  // clearAll fun start here
  const clearAll = () => {
    alert.removeAll()
    setData("")
    setTotalRows(0)
    setClearFilter(false);
    const { start, end } = getLastWeekDates();
    startDate = start;
    endDate = end;
    setDate(`${startDate} To ${endDate}`);
    // console.log(dateRange)
    // console.log(new Date(startDate))
    // console.log(new Date(endDate))
    setDateRange({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      key: 'selection',
    })
    setSelectedOption("Select Email/Phone");
    isGetIpLogsCalled.current = false;
    if(startDate === start && end === endDate)
    {
      // console.log("clearall")
      getIpLogs(pageNo, perPage);
    }
  }//clearAll ends here

  //handlePerRowsChange fun start
  const handlePerRowsChange = async(newPerPage) => {
    alert.removeAll()
    setData([]);
    setLoading(true);
    if(mounted)
    {
      try {
        // console.log(newPerPage);
        setPerPage(newPerPage);
        // console.log("rowChange perPage >> " + perPage);
        let tempDate = date.split(` To `);
        startDate = tempDate[0];
        endDate = tempDate[1];
        isGetIpLogsCalled.current = false;
        // console.log("startDate >> " + startDate );
        // console.log("endDate >> " + endDate);
        // console.log("handlePerRowsChange >> ")
        await getIpLogs(1, newPerPage);
      }
      catch (error) {
        console.error(error);
        // Handle the error here
      }
      finally {
        setLoading(false);
      }
    }
  }//handlePerRowsChange ends here
  //expandableRowComponent start here 
  const expandableRowComponent = ({ data }) => {
    // console.log(data)
    return (
        <>
        <div class="tableAccordion">
          <ul>
            <li>
              <label><strong>{data.Organization} </strong></label>
              <div class="location">
              <img style={{top:"4px", right: "4px"}} src={mapPinImg} alt='mapPin' />
              <label> {data.Address === "" || data.Address === null ? "-" : `${data.Address}, ${data.City}, ${data.State}  ${data.Zip}`} </label>
              </div>
              <label> {data.IsProxy === "" || data.IsProxy === null ? `Proxy :  -  ` : `Proxy : ${data.IsProxy}` }</label>
              <label>{data.Longitude === "" || data.Longitude === null ? `Latitude :  -` : `Latitude : ${data.Longitude}`} </label>
              <label> { data.Latitude === "" || data.Latitude === null ? `Longitude :  -` :`Longitude :  ${data.Latitude}`} </label>
            </li>
            <li className='brandName'>
              <label> { data.Device === "" || data.Device === null ? `Device :  --  ` :`Device :  ${data.Device}`} </label>
              <label> { data.brand === "" || data.brand === null ? `Brand :  --  ` :`Brand :  ${data.brand}`} </label>
              <label> { data.model === "" || data.model === null ? `Model :  --` :`Model :  ${data.model}`} </label>
              <label> { data.Type === "" || data.Type === null ? `Type :  -- ` :`Type :  ${data.Type}`} </label>
              <label> { data.browserName === "" || data.browserName === null ? `Browser Name :  -- ` :`Browser Name :  ${data.browserName}`} </label>
              <label> { data.Browser_Version === "" || data.Browser_Version === null ?`Browser Version :  - ` :`Browser Version :  ${data.Browser_Version}`} </label>
              <label> { data.OS_Name === "" || data.OS_Name === null ? `OS Name :  -- `:`OS Name :  ${data.OS_Name}`} </label>
              <label> { data.OS_Version === "" || data.OS_Version === null ? `OS Version :  -- ` :`OS Version :  ${data.OS_Version}`} </label>
              <label> { data.OS_Platform === "" || data.OS_Platform === null ? `OS Platform :  -- ` :`OS Platform :  ${data.OS_Platform}`} </label>
            </li>
          </ul>
        </div>
        </>
    );
  };//expandableRowComponent ends here 
  // csvDownload function start here
 const csvDownload = async() => 
 {
    alert.removeAll()
    // setIsFullDownload(1);
    isGetIpLogsCalled.current = false;
    isFullDownload = 1;
    let tempDate = date.split(` To `);
    startDate = tempDate[0];
    endDate = tempDate[1];
    await getIpLogs();
    // console.log( " >> " + csvData);
    // console.log("binary to decode >> " + atob(csvData))
  if(csvData !== null)
  {
    exportToCSV(csvData, "ip_log")
  }
  else
  {
    alert.show("You have No Data")
  }
 }//csvDownload ends Here
 //exportToCSV start here  
  function exportToCSV(csvData, fileName) {

      let decodedData = atob(csvData);
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      // Convert the CSV data to a 2D array
      const dataArray = decodedData.split('\n').map(row => row.split(','));
      // console.log((dataArray))
      const ws = XLSX.utils.aoa_to_sheet(dataArray);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `${fileName}${fileExtension}`);

  }////exportToCSV ends here
  return (
    <>
    <div>
    <Header />
      <section className="pageContent">
        <div className="pageSearch"> 
          <select value={selectedOption} onChange={handleSelectChange}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <span className="filed dateSelections">
            <input 
             ref={searchInputRef}
              onFocus={togglePicker} 
              type="search"
              className="input" 
              placeholder="Select Date Range" 
              id="datePicker" 
              value={date}
             /> 
            {showPicker && (
              <div ref={dateRangePickerRef}>
                <DateRangePicker
                  onChange={item => selectDateRange(item)}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={[dateRange]}
                  direction="horizontal"
                />
                </div>
             )} 
          </span>		
          <span className="filed searchBtn">
            {/* <div className="inputLoader" style={{display:"block"}}>
					    <span className="spinLoader small"></span>
				    </div> */}
            <input onClick={getIpLogsViaSearch} id="searchButton" type="button" value="" />
          </span>
        { clearFilter === true ? 
            <span className="filed clearBtn">
              <input type="button" onClick={clearAll} value="Clear All" style={{display:"block"}} id="clearAll" />
            </span>
          : "" }
        <div class="downloads" onClick={csvDownload}>
          <img src={dawnloadImg} alt="dawnloadImage"/>
          <button>
          Download
        </button> 
        {isDownload === true ?
        <div className="">
          <span className="spinLoader small" ></span>
        </div>
        : ""}
       </div>
      </div>
      <div className="dataTable" id="ipLogListing">
        <DataTable
        title={`IP Log Total Records : ${totalRows}` }
        persistTableHead
        className="custom-data-table"
        columns={columns}
        data={data}
        progressPending={loading}
        progressComponent={<Triangle
            height="80"
            width="80"
            color="#2ba983"
            ariaLabel="triangle-loading"
            wrapperStyle={{justifyContent:"center",textAlign:"center"}}
            wrapperClassName=""
            visible={true}
          />}
        customStyles={customStyles}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        onChangePage={pageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        expandableRows
        expandableRowsComponent={expandableRowComponent}
        direction="auto"
        highlightOnHover
        responsive
        striped
        actions
        />
      </div>
      
    </section>
    </div>
    </>
  )
}
export default ListingPage
