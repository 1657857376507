/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import logo from '../images/browzedLogo.png'
import leftImage from '../images/login left side.png'
import eyeInActive from '../images/eye_inactive.png'
import eyeActive from '../images/eye_active.png'
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";

const LoginPage = () => 
{
  const navigate = useNavigate();
  const alert = useAlert();
  // const [isLoggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => 
  {
    const trackerCode = sessionStorage.getItem("trackerCode");
    // console.log("trackerCode >> " + trackerCode);
    if (trackerCode && trackerCode !== "undefined" && trackerCode.trim() !== "") {
      // If trackerCode is present and not "undefined" or an empty string, navigate to the dashboard
      navigate('/dashboard');
    } else {
      // If trackerCode is not present or is "undefined" or an empty string, stay on the login page
      navigate('/');
    }
  }, [navigate])

  

  const login = async() => 
  {
    try
    {
        let usernameErrorMessage = '';
        let passwordErrorMessage = '';
        // Validate username and password
        if(username.trim() === ''  && password.trim() === '')
        {
          // console.log("if")
          usernameErrorMessage = 'Please enter username';
          setUsernameError(usernameErrorMessage);
          passwordErrorMessage = 'Please enter password';
          setPasswordError(passwordErrorMessage)
        }
        else if (username.trim() === '') 
        {
          usernameErrorMessage = 'Please enter username';
          setUsernameError(usernameErrorMessage);
        }
        else if (username.length < 7 || username.length > 15)
        {
          setUsernameError('Username must be between 7 and 15 characters');
        }
        else if (password.trim() === '') 
        {
          passwordErrorMessage = 'Please enter password';
          setPasswordError(passwordErrorMessage)
        }
        else if (password.length < 7 || password.length > 15) {
          setPasswordError('Password must be between 7 and 15 characters');
        }
        else
        {
          setIsLoading(true);
          const apiUrl = 'https://ipapibeta.datazapp.com/login';
          const formData = new URLSearchParams();
          formData.append('username', username);
          formData.append('password', password);

          const response = await fetch(apiUrl,  
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
             },
            body: formData
          })
        
          // console.log("response >> ", response);
          let status = response.status;
          let message = response.statusText;
          const responseData = await response.json();
          // console.log(status + " >>> " + message);
          // console.log(responseData)
          // Check if the API response indicates successful login
          if (status === 200) 
          {
            let token = responseData.Token;
            let trackerCode = responseData.TrackerCode;
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("trackerCode", trackerCode);
            sessionStorage.setItem('companyName', responseData.SourceURL);
            sessionStorage.setItem('username', responseData.Name);
            setIsLoading(false);
            // setLoggedIn(true);
            let tempTrackerCode = sessionStorage.getItem("trackerCode");
            // let tempToken = sessionStorage.getItem("token");
            if(tempTrackerCode !== undefined || tempTrackerCode !== "undefined" || tempTrackerCode !== "" )
            {
              navigate('/dashboard');
            }
            else
            {
              navigate('/');
            }
          } 
          else if(status === 204 )
          {
            setIsLoading(false);
            alert.show(message)
          }
          else if (status === 500)
          {
            setIsLoading(false);
            alert.show(message)
          }
          else if (status === 401)
          {
            setIsLoading(false);
            alert.error(message)
          }
        } 
      }catch (error) {
        console.error(error);
        alert.show(error)
        // Handle the error here
      }  
    }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // const termsAndConditions = () => {
  //   navigate("/termsandconditions")
  // }
  return (
    <div className="accessSystem">
      <div className="grid2">
        {/* Graphic */}
        <div className="graphic">
          <div className="logo">
              <img src={logo} alt="" />
            </div>
          <div className="label">
            {/* <h1>Manage Your Business Account</h1>
            <p>Donec dictum nisl nec mi lacinia, sed maximus tellus eleifend. Proin molestie cursus sapien ac eleifend.</p> */}
            <div className="loginLeftImage">
              <img src={leftImage} alt="" />
            </div>
          </div>
        </div>

        {/* Form */}
        <div className="userForm">
          {/* User Login */}
          <div className="gradientBox" id="login">
            <form id="frmLogin" name="frmLogin" method="post">
              <h3>Login to your Account <span></span></h3>
              <h4>Enter your details to login.</h4>

              <div className="alert alert-error" style={{ display: "none" }}>
                Message sending error.
              </div>
              <div className="alert alert-warning" style={{ display: "none" }}>
                Message sending warning.
              </div>
              <ul>
                <li>
                  <div className="formLabel">
                    <label>Tracker Code</label>
                  </div>
                  <div className="emailUsername">
                    <input
                      type="email"
                      placeholder="Enter Your Tracker Code"
                      tabIndex="1"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value)
                        setUsernameError('')
                      }}
                    />
                    {usernameError && (
                    <div className="validation">
                      <span>{usernameError}</span>
                    </div>
                  )}
                  </div>
                </li>
                <li>
                  <div className="formLabel">
                    <label>Password</label>
                  </div>
                  <div className="passwordField">
                    <input
                      className="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value)
                        setPasswordError('')
                      }}
                      tabIndex="2"
                    />
                    <a id="eye" onClick={togglePasswordVisibility}>
                      <span id="showPass" style={{ display: showPassword ? "none" : "block" }}>
                        <img src={eyeActive} alt="Hide Password" />
                      </span>
                      <span id="hidePass" style={{ display: showPassword ? "block" : "none" }}>
                        <img src={eyeInActive} alt="Show Password" />
                      </span>
                    </a>
                  </div>
                   {passwordError && (
                    <div className="validation">
                      <span>{passwordError}</span>
                    </div>
                  )}
                </li>
                <li className="loginButtonPanel">
                  <div className="buttonLeft">
                    <div className="submitBtn">
                    {isLoading === true ? <div class="btnLoader" id="btnLoader" >
                        <span class="spinLoader"></span>
                      </div> : ""}
                      <input
                        type="button"
                        onClick={login}
                        className="btn"
                        tabIndex="3"
                        id="btnLoginSubmit"
                        name="btnLoginSubmit"
                        value={isLoading === true ? "   " : "Login"}
                      />
                    </div>
                    {/* <span className="termsConditions">
                      By clicking login, you agree to our&nbsp;
                      <a onClick={termsAndConditions} target="_blank" style={{ color: '#FF4040', textDecoration: "underline", cursor:"pointer" }}>Terms &amp; Conditions</a>
                    </span> */}
                  </div>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
