import React,{useEffect, useState} from 'react'
import Header from '../component/Header';
import noDataImg from '../images/no-data.png'
import { useNavigate,useLocation } from "react-router-dom";
import {Triangle} from 'react-loader-spinner'
import { useAlert } from "react-alert";
import { 
  BarChart, 
  Bar,
  // LineChart,
  // Line,
  // Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
 } from 'recharts';

var dailyCountDataPoints = [
  // {
  //   "name": "14 august",
  //   "Counts": 239,
  //   "AppendCounts": 48
  //   },
  //   {
  //     "name": "16 august",
  //     "Counts": 300,
  //     "AppendCounts": 84
  //     },
  //     {
  //       "name": "18 august",
  //       "Counts": 250,
  //       "AppendCounts": 23
  //       },
  //       {
  //         "name": "20 august",
  //         "Counts": 275,
  //         "AppendCounts": 75
  //       },
  // {
  //   "name": "15 july",
  //   "counts": `794`
  // },
  // {
  //   "name": "19 july",
  //   "counts": `894`
  // },
  // {
  //   "name": "21 july",
  //   "counts": `494`
  // },
  // {
  //   "name": "23 july",
  //   "counts": `594`
  // }
];
// var weeklyCountDataPoints = [];
var summaryData;
var summaryDataArray = [];
  // "Visitors": {
  //   "Order": 1,
  //   "Count": 333
  // },
  // "EmailAppend": {
  //   "Order": 6,
  //   "Count": 56
  // },
  // "PhoneAppend": {
  //   "Order": 5,
  //   "Count": 15
  // },
  // "NameAndPhoneAppend": {
  //   "Order": 4,
  //   "Count": 56
  // },
  // "CellAppend": {
  //   "Order": 7,
  //   "Count": 23
  // },
  // "DesktopVisitors": {
  //   "Order": 2,
  //   "Count": 250
  // },
  // "SmartPhoneVisitors": {
  //   "Order": 3,
  //   "Count": 81
  // }
// }
// console.log(transformedData.length)
const colorMapping = {
  Visitors: '#4f81bc',
  EmailAppend: '#8064a1',
  PhoneAppend: '#23bfaa',
  NameAndPhoneAppend: '#9bbb58',
  CellAppend: '#c0504e',
  DesktopVisitors: '#4aacc5',
  SmartPhoneVisitors: '#0088FE',
};

const getCategoryColor = (category) =>  colorMapping[category] || '#000000';

const DashboardPage = () => 
{
  const alert = useAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const[dailyCount, setDailyCount] = useState(0);
  const[weeklyCount, setWeeklyCount] = useState(0);
  const[weeklyAppendCount, setWeeklyAppendCount] = useState();
  const [mounted, setMounted] = useState(true);
  // Preload noDataImg when the component mounts
  useEffect(() => {
    const preloadNoDataImg = () => {
      const img = new Image();
      img.src = noDataImg;
    };
    preloadNoDataImg();
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const  getCounterData = () =>
    {
      let tempTrackerCode = sessionStorage.getItem("trackerCode");
      let temptoken = sessionStorage.getItem("token");
      if(tempTrackerCode === "" || tempTrackerCode === undefined || tempTrackerCode === "undefined" )
      {
        navigate('/');
      }
      else
      {
        // setToken(temptoken);
        // setTrackerCode(tempTrackerCode);
        if(mounted === true)
        {
          displayCounterData(temptoken, tempTrackerCode)
          setMounted(false)
        } 
      }
    }
  //displayCounterData function start here  
  const displayCounterData = async (temptoken, tempTrackerCode) => 
  {
    try {
      setLoading(true)
      const token = temptoken;
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const data = {
        trackercode: tempTrackerCode,
      };
       const dataString= JSON.stringify(data);
       const response = await fetch(`https://ipapibeta.datazapp.com/CounterData?${dataString}`,  {
        method: 'GET',
        headers,
      });
      let status = response.status;
      let message = response.statusText;
      if(response.status === 200)
      {
        const responseData = await response.json();
        // console.log(responseData)
        // setTrackerCode(responseData.TrackerCode);
        // setDailyAppendCount(responseData.DailyAppendCount);
        setDailyCount(responseData.DailyCount);
        setWeeklyAppendCount(responseData.WeeklyAppendCount);
        setWeeklyCount(responseData.WeeklyCount);
				dailyCountDataPoints = (responseData.DailyCountList).map((data) => {
          return {
            "name": formatDate(data.Date),
            "Website Visitors": `${data.Counts}`,
            "Matches": `${data.AppendCounts}`,
          };
        });
        // console.log(dailyCountDataPoints)
        console.log()
        summaryData = responseData.Summary
         summaryDataArray = Object.keys(summaryData).map(category => ({
          name: category,
          count: summaryData[category].Count,
          fill: getCategoryColor(category),
        }));
        console.log(summaryDataArray)
        setLoading(false)
      }
      else if(status === 204 )
      {
        setLoading(false);
        alert.show(message)
      }
      else if (status === 500)
      {
        setLoading(false);
        alert.show(message)
      }
      else if (status === 401)
      {
        setLoading(false);
        alert.error(message);
        sessionStorage.clear();
        navigate("/")
      }

    } catch (error) {
      console.error(error);
      // Handle the error here
    }
  };//displayCounterData ends here
  //formatDate fun start here
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options);
  };//formatDate ends here
  useEffect(() => 
  {
    alert.removeAll()
    const trackerCode = sessionStorage.getItem("trackerCode");
    if(trackerCode !== undefined || trackerCode !== "undefined" || trackerCode !== "" )
    {
      navigate('/dashboard');
    }
    else
    {
      navigate('/');
    }
    getCounterData();
  }, []);
  // const TooltipContent = ({ payload }) => {
  //   if (payload && payload.length) {
  //     const data = payload[0].payload; // Assuming the payload contains only one data point
  //     return (
  //       <div style={{backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', padding:"5px"}} className="custom-tooltip">
  //         <p style={{fontWeight:"bold",padding:"2px"}}>{`Week: ${data.week}`}</p>
  //         <p style={{color:"#8884d8",padding:"2px"}}>{`Counts: ${data.counts}`}</p>
  //         <p style={{padding:"2px"}}>{`Start Date: ${data.weekStartDate}`}</p>
  //         <p style={{padding:"2px"}}>{`End Date: ${data.weekEndDate}`}</p>
  //       </div>
  //     );
  //   }
  //   return null;
  // };
  return (
    <>
      <Header location={location.state} />
      <section className="pageContent">
      {loading === true ? 
          <Triangle
            height="80"
            width="80"
            color="#2ba983"
            ariaLabel="triangle-loading"
            wrapperStyle={{justifyContent:"center",textAlign:"center",top:"30vh"}}
            wrapperClassName=""
            visible={true}
          />
      :
        <div className="wrapper">
          <div className="graphAndDataCard">
            <div className="dataCardList">
              <div className="dataCard">
                <div className="visitor yellowText green">
                  <label>Visitor Today</label>
                  <strong>{dailyCount}</strong>							
                </div>
              </div>
              <div className="graphImage">
                <label>Daily Visitors Graph</label>
                {dailyCountDataPoints.length > 0 ?
                ( 
                  <>
                    <BarChart
                      width={600}
                      height={400}
                      data={dailyCountDataPoints}
                      margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <Legend 
                        verticalAlign="top"
                        align="center"
                        height={30}
                        />
                      <XAxis dataKey="name" />
                      <YAxis   label={{ value: 'Counts', angle: -90, position: 'insideLeft' }} />
                      <Tooltip contentStyle={{ background: 'grey', border: '1px solid #ccc',  color:'white'  }}   />
                      <Bar dataKey="Website Visitors" fill="#fd8d3c" barSize={30} />
                      <Bar dataKey="Matches" fill="#f2f284" barSize={30} />
                    </BarChart>
                    <p style={{textAlign:"center", margin:'-15px'}}>{"Date"}</p>
                  </>
                )
                : 
                <img style={{width:"580px", height:"370px"}} src={noDataImg} alt="no data" />
                }
              </div>
            </div>
            <div className="dataCardList">
              <div className="dataCard">
                <div className='visitor yellow weeklyVisitor'>
                <div className="weeklyData greenText left">
                  <label>Weekly Count</label>
                  <strong>{weeklyCount}</strong>							
                </div>
                <div className="weeklyData blackText">
                  <label>Weekly Append Count </label>
                  <strong>{weeklyAppendCount}</strong>
                </div>
                </div>
              </div>
              <div className="graphImage">
                <label>Visitor Summary (Last 10 Days)</label>  
                {summaryDataArray.length > 0 ?    
                 (
                  <>
                  <BarChart 
                    width={600}
                    height={400}
                    data={summaryDataArray}
                    layout="vertical"
                    margin={{ top: 5, right: 30, left: 70, bottom: 5 }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number"  />
                    <YAxis type="category"  dataKey="name" interval={0} width={100} tick={{ x:0, dy: 0}}/>
                    <Tooltip contentStyle={{ background: 'grey', border: '1px solid #ccc', color:'white' }}   />
                    <Bar barSize={30}  dataKey="count" fill="fill"/>
                  </BarChart>
                    <p style={{textAlign:"center",  margin:'-15px', marginRight:'-162px'}}>{"Counts"}</p>
                  </>
                 )
                 : 
                  <img style={{width:"580px", height:"370px"}} src={noDataImg} alt="no data" />
                }
              </div>
            </div>
          </div>
        </div>
      }
	    </section>
    </>
  )
}
export default DashboardPage;